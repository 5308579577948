<template>
  <defaultLayout>
    <div class="report-container">
      <a-row>
        <a-page-header
          style="border: 1px solid rgb(235, 237, 240)"
          title="รายงาน"
          subTitle="ดูรายงานประวัติและดาวน์โหลด"
        />
        <div class="search-container">
          <a-card size="small" title="สร้างรายงาน" style="width: 100%">
            <a-form layout="horizontal">
              <a-form-item
                label="ประเภทรายงาน"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-select
                  size="large"
                  defaultValue="daily"
                  v-model="reportType"
                >
                  <a-select-opt-group
                    v-if="
                      this.user &&
                      'report_permissions' in this.user &&
                      this.user.report_permissions.length > 0
                    "
                  >
                    <span slot="label" style="font-size: 20px"
                      ><a-icon type="star" />&nbsp; รายงานพิเศษ
                      {{ user.display_name }}
                    </span>
                    <a-select-option
                      value="geofence_current_report"
                      v-if="allowReport('geofence_current_report')"
                    >
                      <span class="select-menu-item">
                        - ตรวจสอบพาหนะในสถานีทุกคัน</span
                      >
                    </a-select-option>
                    <a-select-option
                      value="fuel_rate_report"
                      v-if="allowReport('fuel_rate_report')"
                    >
                      <span class="select-menu-item">
                        - รายงานการใช้เชื้อเพลิง</span
                      >
                    </a-select-option>
                    <a-select-option
                      value="fleet_summary_report"
                      v-if="allowReport('fleet_summary_report')"
                    >
                      <span class="select-menu-item">
                        - รายสรุปการใช้งานรถ</span
                      >
                    </a-select-option>
                    <a-select-option
                      value="temperatureWithChart"
                      v-if="allowReport('temperature_with_chart')"
                    >
                      <span class="select-menu-item">
                        - รายงานอุณหภูมิ + กราฟอุณหภูมิ
                      </span>
                    </a-select-option>
                    <a-select-option
                      value="report_temperature_pdf"
                      v-if="allowReport('report_temperature_pdf')"
                    >
                      <span class="select-menu-item">
                        - รายงานอุณหภูมิ (PDF)</span
                      >
                    </a-select-option>
                    <a-select-option
                      value="all_device_report"
                      v-if="allowReport('all_device_report')"
                    >
                      <span class="select-menu-item"> - รายงานพี่ดวง</span>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group>
                    <span slot="label" style="font-size: 20px"
                      ><a-icon type="file-text" />&nbsp;รายงานทั่วไป
                    </span>
                    <a-select-option value="daily">
                      <span class="select-menu-item">1. รายงานประจำวัน</span>
                    </a-select-option>
                    <a-select-option value="trip">
                      <span class="select-menu-item">2. รายงานการเดินทาง</span>
                    </a-select-option>
                    <a-select-option value="trip24hr">
                      <span class="select-menu-item"
                        >3. รายงานการเดินทาง (ตัดเที่ยงคืน)</span
                      >
                    </a-select-option>
                    <a-select-option value="tripOver4hour">
                      <span class="select-menu-item"
                        >4. รายงานขับรถต่อเนื่องเกิน 4 ชั่วโมง</span
                      >
                    </a-select-option>
                    <a-select-option value="fuel">
                      <span class="select-menu-item">5. รายงานเชื้อเพลิง</span>
                    </a-select-option>
                    <a-select-option value="temperature">
                      <span class="select-menu-item">6. รายงานอุณหภูมิ</span>
                    </a-select-option>
                    <a-select-option value="overspeed">
                      <span class="select-menu-item">7. รายงานความเร็ว</span>
                    </a-select-option>
                    <a-select-option value="position">
                      <span class="select-menu-item"
                        >8. รายงานตำแหน่งพาหนะ</span
                      >
                    </a-select-option>
                    <a-select-option value="fuelChart">
                      <span class="select-menu-item">9. กราฟเชื้อเพลิง</span>
                    </a-select-option>
                    <a-select-option value="tempChart">
                      <span class="select-menu-item">10. กราฟอุณหภูมิ</span>
                    </a-select-option>
                    <a-select-option value="pto">
                      <span class="select-menu-item">11. รายงาน PTO</span>
                    </a-select-option>
                    <a-select-option value="idling">
                      <span class="select-menu-item">12. รายงาน Idling</span>
                    </a-select-option>
                    <a-select-option value="idlingSummary">
                      <span class="select-menu-item"
                        >13. รายงาน Idling+PTO Summary</span
                      >
                    </a-select-option>
                    <a-select-option value="geofences">
                      <span class="select-menu-item"
                        >14. รายงานสถานีถึงสถานี</span
                      >
                    </a-select-option>
                    <a-select-option value="summary">
                      <span class="select-menu-item">15. รายงานสรุป</span>
                    </a-select-option>
                    <a-select-option value="fuelUsedRateSummary">
                      <span class="select-menu-item"
                        >16. รายงานสรุปพร้อมอัตราสิ้นเปลือง</span
                      >
                    </a-select-option>
                    <a-select-option value="idlingOpenPTO">
                      <span class="select-menu-item"
                        >17. จอดรถไม่ดับเครื่องยนต์และ PTO</span
                      >
                    </a-select-option>
                    <a-select-option value="tripA4">
                      <span class="select-menu-item"
                        >18. รายงานเดินทาง A4
                      </span>
                    </a-select-option>
                    <a-select-option value="speedChart">
                      <span class="select-menu-item">19. กราฟความเร็ว </span>
                    </a-select-option>
                    <a-select-option value="vehicleUsageSummary">
                      <span class="select-menu-item"
                        >20. สรุปข้อมูลการใช้งานในภาพรวม
                      </span>
                    </a-select-option>
                    <a-select-option value="enterExitGeofenceReport">
                      <span class="select-menu-item"
                        >21. รายงานเข้า-ออกสถานี
                      </span>
                    </a-select-option>
                    <a-select-option value="summaryByDay">
                      <span class="select-menu-item"
                        >22. รายงานสรุปแบบแยกวัน
                      </span>
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-item>

              <a-form-item
                v-if="displayDateCond()"
                :label="reportType === 'fuelChart' ? 'วันที่' : 'ช่วงเวลา'"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-date-picker
                  v-if="reportType === 'fuelChart'"
                  :locale="locale"
                  format="D MMM YYYY"
                  size="large"
                  placeholder="เลือกวันที่"
                  v-model="selectedDate"
                  :allowClear="true"
                />
                <a-range-picker
                  v-else
                  size="large"
                  format="D MMM YYYY HH:mm"
                  :placeholder="['เริ่มต้น', 'สิ้นสุด']"
                  @change="onChangeDateRange"
                  v-model="dateRange"
                  :allowClear="true"
                  :locale="locale"
                  :showTime="{
                    defaultValue: [
                      moment().hour(0).minute(0).second(0).millisecond(0),
                      moment().hour(23).minute(59).second(59).millisecond(999),
                    ],
                  }"
                />
              </a-form-item>

              <a-form-item
                v-show="reportType !== 'all_device_report'"
                label="กลุ่มพาหนะ"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-select
                  :defaultActiveFirstOption="true"
                  defaultValue="เลือกกลุ่มพาหนะ"
                  @change="onChangeVehicleGroup"
                  size="large"
                  :value="selectedVehicleGroup"
                >
                  <a-select-option
                    v-for="vehicleGroup in vehicleGroups"
                    :key="vehicleGroup.name"
                    :value="vehicleGroup.name"
                    >{{ vehicleGroup.label }}</a-select-option
                  >
                </a-select>
              </a-form-item>

              <a-form-item
                v-show="reportType !== 'all_device_report'"
                label="พาหนะ"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-select
                  show-search
                  size="large"
                  :mode="isChartType(reportType) ? 'default' : 'multiple'"
                  placeholder="เลือกพาหนะ"
                  :value="selectedVehicles"
                  @change="onChangeSelectedVehicles"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                >
                  <a-select-option
                    v-if="!isChartType(reportType)"
                    value="selectAll"
                  >
                    <span class="select-menu-item" color="blue">
                      เลือกทั้งหมด
                    </span>
                  </a-select-option>
                  <a-select-option
                    v-for="vehicle in vehicleList"
                    :key="vehicle.value"
                  >
                    <span class="select-menu-item">{{ vehicle.label }} </span>
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                label="เงื่อนไขมากกว่า (นาที)"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                v-show="
                  ['overspeed', 'idling', 'idlingOpenPTO'].includes(reportType)
                "
              >
                <a-select
                  :defaultActiveFirstOption="true"
                  :defaultValue="0"
                  v-model="minuteOver"
                  suffix="นาที"
                >
                  <a-select-option
                    v-for="(minute, index) in allMinute"
                    :key="`minute-${index}`"
                    :value="index"
                    >{{ index }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item
                label="ข้อมูลทุกๆเวลา"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                v-show="
                  [
                    'temperatureWithChart',
                    'temperature',
                    'report_temperature_pdf',
                    'position',
                  ].includes(reportType)
                "
              >
                <a-select
                  :defaultActiveFirstOption="true"
                  v-model="minSecData"
                  suffix="วินาที/นาที"
                >
                  <a-select-option value="0">ทั้งหมด</a-select-option>
                  <a-select-option value="30">30 วินาที</a-select-option>
                  <a-select-option value="60">1 นาที</a-select-option>
                  <a-select-option value="180">3 นาที</a-select-option>
                  <a-select-option value="300">5 นาที</a-select-option>
                  <a-select-option value="600">10 นาที</a-select-option>
                  >
                </a-select>
              </a-form-item>
              <a-form-item
                label="สถานี"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                v-if="['enterExitGeofenceReport'].includes(reportType)"
              >
                <multiple-select-geofence
                  @onHandleChange="onMultiGeofenceSelectChange"
                />
              </a-form-item>
              <a-form-item :wrapper-col="buttonCol">
                <a-button
                  type="primary"
                  @click="generateReport"
                  :loading="reportLoading"
                  :disabled="disabledDownloadButton"
                >
                  {{ isChartType(reportType) ? 'ดูรายงาน' : 'ดาวน์โหลดรายงาน' }}
                </a-button>
                <a-button
                  v-show="reportType !== 'all_device_report'"
                  type="default"
                  @click="clearData"
                  :style="{ marginLeft: '8px' }"
                >
                  เริ่มใหม่
                </a-button>
              </a-form-item>
            </a-form>
          </a-card>
        </div>
      </a-row>

      <a-row v-if="isChartType(reportType)">
        <div v-if="reportType === 'tempChart'">
          <div
            v-for="(data, key) in tempChartData"
            :key="key"
            class="search-container"
          >
            <a-spin :spinning="reportLoading">
              <a-icon
                slot="indicator"
                type="loading"
                style="font-size: 24px"
                spin
              />
              <a-card
                size="small"
                :title="
                  tempChartData.length === 1
                    ? chartTitle
                    : `กราฟอุณหภูมิ ${key + 1} รถทะเบียน ${
                        selectedVehicleData ? selectedVehicleData.name : '-'
                      } ${`ช่วงวันที่ ${startTimeStr} ถึง ${endTimeStr}`} 
          ระยะทางรวม ${chartTotalDistance} กม.`
                "
                style="width: 100%"
              >
                <tempChart
                  :tempChartData="data"
                  :timeRange="[dateRange[0], dateRange[1]]"
                />
              </a-card>
            </a-spin>
          </div>
        </div>
        <div v-else class="search-container">
          <a-spin :spinning="reportLoading">
            <a-icon
              slot="indicator"
              type="loading"
              style="font-size: 24px"
              spin
            />
            <a-card
              v-if="
                selectedVehicleData &&
                'id' in selectedVehicleData &&
                reportType === 'fuelChart'
                  ? selectedDate
                  : dateRange[0] && dateRange[1]
              "
              size="small"
              :title="chartTitle"
              style="width: 100%"
            >
              <a-button-group slot="extra" v-if="reportType == 'fuelChart'">
                <a-button
                  type="default"
                  @click="handleDayBeforeClick"
                  :loading="reportLoading"
                >
                  <a-icon type="left" />วันก่อนหน้า
                </a-button>
                <a-button
                  type="default"
                  @click="handleDayAfterClick"
                  :loading="reportLoading"
                >
                  วัันถัดไป
                  <a-icon type="right" />
                </a-button>
              </a-button-group>

              <div v-if="fuelChartData.length > 0">
                <fuelChart
                  :fuelChartData="fuelChartData"
                  :chartPNGTitle="chartTitle"
                />
              </div>
              <div v-else-if="speedChartData.length > 0">
                <speedChart
                  :overSpeedLimit="overSpeedLimit"
                  :speedChartData="speedChartData"
                />
              </div>
              <div v-else>
                <a-alert
                  message="ไม่พบข้อมูลช่วงเวลาดังกล่าว"
                  type="warning"
                  show-icon
                />
              </div>
            </a-card>
          </a-spin>
        </div>
      </a-row>
    </div>
  </defaultLayout>
</template>

<script>
// @ is an alias to /src
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import locale from 'ant-design-vue/es/date-picker/locale/th_TH';
import { mapActions, mapState, mapGetters } from 'vuex';
import { getVehicles } from '@/lib/vehicle';
import _ from 'lodash';
import moment from '@/lib/time';
import fuelChart from '@/views/components/report/fuelChart';
import tempChart from '@/views/components/report/tempChart';
import speedChart from '@/views/components/report/speedChart';
import report from '@/lib/report';
import AxiosService from '@/plugins/axios';
import endpoints from '@/config/config';
import fileDownload from 'js-file-download';
import multipleSelectGeofence from './components/multipleSelectGeofence.vue';
export default {
  name: 'home',
  components: {
    defaultLayout,
    fuelChart,
    tempChart,
    speedChart,
    multipleSelectGeofence,
  },
  data() {
    return {
      reportType: 'daily',
      labelCol: { lg: { span: 6, offset: 2 }, md: { span: 3, offset: 5 } },
      wrapperCol: { lg: { span: 10, offset: 0 }, md: { span: 10, offset: 0 } },
      buttonCol: { lg: { span: 10, offset: 8 }, md: { span: 10, offset: 8 } },
      vehicleList: [],
      selectedVehicles: [],
      overSpeedLimit: 0,
      selectedVehicleData: undefined,
      dateRange: [
        moment().hour(0).minute(0).second(0).millisecond(0),
        moment().hour(23).minute(59).second(59).millisecond(999),
      ],
      selectedDate: null,
      selectedVehicleGroup: [],
      locale,
      minuteOver: 0,
      minSecData: '0',
      allMinute: [...Array(61).keys()],
      fuelChartData: [],
      tempChartData: [],
      speedChartData: [],
      chartTotalDistance: '',
      selectedAll: false,
      allowReportData: {},
      selectedGeofences: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.authen.user,
      drivers: (state) => state.driver.drivers,
      reportLoading: (state) => state.loading.reportLoading,
      ...mapGetters({
        vehicles: 'vehicle/vehicles',
        vehicleByIMEI: 'vehicle/vehicleByIMEI',
      }),
      geofences: (state) => state.geofence.geofences,
      selectedGroup: (state) => state.authen.selectedGroup,
      vehicleGroups: (state) => state.vehicle.vehicleGroups,
    }),
    disabledDownloadButton() {
      if (this.reportType === 'all_device_report') {
        return false;
      }

      if (this.reportType === 'enterExitGeofenceReport') {
        return (
          this.selectedVehicles.length <= 0 ||
          this.selectedGeofences.length <= 0
        );
      }

      if (this.reportType === 'speedChart' || this.reportType === 'tempChart') {
        return (
          this.selectedVehicles.length <= 0 ||
          !this.dateRange[0] ||
          !this.dateRange[1]
        );
      }

      if (this.reportType === 'fuelChart') {
        return this.selectedVehicles.length <= 0 || !this.selectedDate;
      }

      return this.selectedVehicles.length <= 0;
    },
    startTimeStr() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).format('D MMM YYYY HH:mm')
        : '-';
    },
    endTimeStr() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).format('D MMM YYYY HH:mm')
        : '-';
    },
    chartTitle() {
      return `กราฟ${this.translateChartType()} รถทะเบียน ${
        this.selectedVehicleData ? this.selectedVehicleData.name : '-'
      } ${
        this.reportType == 'fuelChart'
          ? `วันที่ ${moment(this.selectedDate).format('D MMM YYYY')}`
          : `ช่วงวันที่ ${this.startTimeStr} ถึง ${this.endTimeStr}`
      }
          ระยะทางรวม ${this.chartTotalDistance || '-'} กม.`;
    },
  },
  watch: {
    reportType(newValue, oldValue) {
      this.resetChartData();

      let isOldValueChartType = this.isChartType(oldValue);
      let isNewValueChartType = this.isChartType(newValue);

      if (isOldValueChartType !== isNewValueChartType) {
        this.selectedVehicles = [];
      }
    },
  },
  async mounted() {
    //get all drivers
    if (this.vehicles.length <= 0)
      await getVehicles(
        this.user.customer_groups.map((group) => {
          return group._id;
        })
      );

    if (this.geofences.length <= 0) {
      await this.getAllGeofences([this.selectedGroup.id]);
    }

    if (this.drivers.length <= 0) {
      await this.getAllDrivers([this.selectedGroup.id]);
    }

    if (this.vehicleGroups.length > 0) {
      this.onChangeVehicleGroup(this.vehicleGroups[0].name);
    }

    if (
      'report_permissions' in this.user &&
      this.user.report_permissions.length > 0
    ) {
      for (const i in this.user.report_permissions) {
        this.allowReportData[this.user.report_permissions[i].code] = true;
      }
    }
  },
  beforeDestroy() {
    this.resetVehicleState();
    this.resetPositionState();
  },
  methods: {
    moment,
    ...mapActions({
      setReportLoading: 'loading/setReportLoading',
      resetVehicleState: 'vehicle/resetState',
      resetPositionState: 'position/resetState',
      getAllGeofences: 'geofence/getAllGeofences',
      getAllDrivers: 'driver/getAllDrivers',
    }),
    allowReport(code) {
      return code in this.allowReportData ? true : false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleDayBeforeClick() {
      this.selectedDate = moment(this.selectedDate).subtract(1, 'days');
      if (this.reportType == 'fuelChart') this.genFuelChartData();
    },
    handleDayAfterClick() {
      this.selectedDate = moment(this.selectedDate).add(1, 'days');
      if (this.reportType == 'fuelChart') this.genFuelChartData();
    },

    onChangeDateRange(value) {
      if (this.dateRange[0] !== undefined || this.dateRange[1] !== undefined) {
        if (this.dateRange[0].unix() == this.dateRange[1].unix()) {
          this.dateRange[0].subtract(1, 'd').unix();
        }
        this.dateRange = value;
      }
    },
    onChangeVehicleGroup(value) {
      this.selectedVehicleGroup = value;

      /* initial vehicle list options */
      this.vehicleList = [];
      const groupInfo = _.find(this.vehicleGroups, { name: value });
      for (const k in groupInfo.vehicles) {
        const vehicle = groupInfo.vehicles[k];
        if (vehicle.device && vehicle.device.imei) {
          this.vehicleList.push({
            value: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            label: vehicle.name + ' ( ' + vehicle.plate_number + ' ) ',
          });
        }
      }

      /* reset selected vehicle */
      this.selectedVehicles = [];
    },

    onChangeSelectedVehicles(value) {
      this.selectedVehicles = value;
      if (value[0] == 'selectAll') {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }

      if (typeof value == 'number') {
        this.selectedVehicleData = this.vehicleByIMEI(value);
      }
    },

    async generateReport() {
      let selectedImeisTmp = [];
      let dictOfDriver = {};
      for (const k in this.drivers) {
        dictOfDriver[this.drivers[k].license_no] = this.drivers[k];
      }
      if (this.selectedAll) {
        for (let i = 0; i < this.vehicleList.length; i++) {
          selectedImeisTmp.push(this.vehicleList[i].value);
        }
      } else {
        selectedImeisTmp = this.selectedVehicles;
      }
      //////// GA /////////
      switch (this.reportType) {
        case 'geofence_current_report':
          this.$gtag.event(`report_geofenceCurrent_downloaded`);
          break;
        case 'fuel_rate_report':
          this.$gtag.event(`report_fuelRate_downloaded`);
          break;
        case 'fleet_summary_report':
          this.$gtag.event(`report_fleetSummary_downloaded`);
          break;
        case 'report_temperature_pdf':
          this.$gtag.event(`report_temperaturePDF_downloaded`);
          break;

        default:
          this.$gtag.event(`report_${this.reportType}_downloaded`);
          break;
      }
      ////////////////////
      if (this.reportType == 'daily') {
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/report-event`,
          {
            eventType: 'ALL',
            excludeEventType: 'END_TRIP',
            summarizeDriver: true,
            imeis: selectedImeisTmp,
            start: this.dateRange[0].unix(),
            stop: this.dateRange[1].unix(),
            report_type: 'xlsx',
            customergroup: this.selectedGroup.id,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const strTimerange =
              moment.unix(this.dateRange[0].unix()).format('D_MMM_YY') +
              '-to-' +
              moment.unix(this.dateRange[1].unix()).format('D_MMM_YY');
            const fileName = 'Daily_Report_' + strTimerange + '.xlsx';

            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType == 'overspeed') {
        this.setReportLoading(true);
        await report.overSpeedReport(
          ['OVER_SPEED_END'],
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.minuteOver * 60,
          this.geofences,
          dictOfDriver,
          this.selectedGroup.logo
        );
      } else if (this.reportType == 'pto') {
        this.setReportLoading(true);
        await report.ptoReport(
          ['PTO_STOP'],
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.geofences,
          dictOfDriver,
          this.selectedGroup.logo
        );
      } else if (this.reportType == 'trip') {
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/report-trip`,
          {
            imeis: selectedImeisTmp,
            start: this.dateRange[0].unix(),
            stop: this.dateRange[1].unix(),
            customergroup: this.selectedGroup.id,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const strTimerange =
              moment.unix(this.dateRange[0].unix()).format('D_MMM_YY') +
              '-to-' +
              moment.unix(this.dateRange[1].unix()).format('D_MMM_YY');
            const fileName = 'Trip_Report_' + strTimerange + '.xlsx';

            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType == 'trip24hr') {
        this.setReportLoading(true);
        await report.tripReport(
          ['END_TRIP'],
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.geofences,
          '24hr',
          dictOfDriver,
          this.selectedGroup.logo
        );
      } else if (this.reportType == 'tripOver4hour') {
        this.setReportLoading(true);
        await report.tripReport(
          ['END_TRIP'],
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.geofences,
          'over4hour',
          dictOfDriver,
          this.selectedGroup.logo
        );
      } else if (this.reportType == 'tripA4') {
        this.setReportLoading(true);
        await report.tripA4Report(
          ['END_TRIP'],
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.geofences,
          this.selectedGroup.logo
        );
      } else if (this.reportType == 'geofences') {
        this.setReportLoading(true);
        const vehiclesData = this.vehicles.map((vehicle) => {
          return {
            plate: vehicle.plate_number,
            name: vehicle.name,
            odo_canbus_offset: vehicle.odo_canbus_offset,
            canbus_fuel_enable: vehicle.canbus_fuel_enable,
            odo_canbus_enable: vehicle.odo_canbus_enable,
            overspeed_limit: vehicle.overspeed_limit,
            imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
          };
        });

        await report.geofenceReport(
          vehiclesData,
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.selectedGroup
        );
      } else if (
        this.reportType == 'temperatureWithChart' ||
        this.reportType == 'temperature'
      ) {
        this.setReportLoading(true);
        const vehiclesData = this.vehicles.map((vehicle) => {
          return {
            plate: vehicle.plate_number,
            name: vehicle.name,
            odo_canbus_offset: vehicle.odo_canbus_offset,
            canbus_fuel_enable: vehicle.canbus_fuel_enable,
            odo_canbus_enable: vehicle.odo_canbus_enable,
            canbus_truck_enabled: vehicle.canbus_truck_enabled,
            overspeed_limit: vehicle.overspeed_limit,
            imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
          };
        });
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/report-temperature`,
          {
            imeis: selectedImeisTmp,
            start: this.dateRange[0].unix(),
            stop: this.dateRange[1].unix(),
            customergroup: this.selectedGroup.id,
            sec: this.minSecData,
            withChart: this.reportType == 'temperatureWithChart',
            vehicles: vehiclesData,
            dictOfDriver: dictOfDriver,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const strTimerange =
              moment.unix(this.dateRange[0].unix()).format('D_MMM_YY') +
              '-to-' +
              moment.unix(this.dateRange[1].unix()).format('D_MMM_YY');
            const fileName = 'Temperature_Report_' + strTimerange + '.xlsx';
            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType == 'report_temperature_pdf') {
        this.setReportLoading(true);
        const vehiclesData = this.vehicles.map((vehicle) => {
          return {
            plate: vehicle.plate_number,
            name: vehicle.name,
            odo_canbus_offset: vehicle.odo_canbus_offset,
            canbus_fuel_enable: vehicle.canbus_fuel_enable,
            odo_canbus_enable: vehicle.odo_canbus_enable,
            canbus_truck_enabled: vehicle.canbus_truck_enabled,
            overspeed_limit: vehicle.overspeed_limit,
            imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
          };
        });
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/report-temperature-pdf`,
          {
            imeis: selectedImeisTmp,
            start: this.dateRange[0].unix(),
            stop: this.dateRange[1].unix(),
            customergroup: this.selectedGroup.id,
            sec: this.minSecData,
            vehicles: vehiclesData,
            dictOfDriver: dictOfDriver,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const strTimerange =
              moment.unix(this.dateRange[0].unix()).format('D_MMM_YY') +
              '-to-' +
              moment.unix(this.dateRange[1].unix()).format('D_MMM_YY');
            const fileName = 'Temperature_Report_' + strTimerange + '.zip';
            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType == 'position') {
        this.setReportLoading(true);
        const vehiclesData = this.vehicles.map((vehicle) => {
          return {
            plate: vehicle.plate_number,
            name: vehicle.name,
            odo_canbus_offset: vehicle.odo_canbus_offset,
            canbus_fuel_enable: vehicle.canbus_fuel_enable,
            odo_canbus_enable: vehicle.odo_canbus_enable,
            canbus_truck_enabled: vehicle.canbus_truck_enabled,
            overspeed_limit: vehicle.overspeed_limit,
            imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
          };
        });

        await report.positionReport(
          vehiclesData,
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.minSecData,
          this.geofences,
          dictOfDriver,
          this.selectedGroup.logo,
          this.selectedGroup.id
        );
      } else if (this.reportType == 'fuel') {
        this.setReportLoading(true);
        await report.fuelReport(
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.geofences,
          dictOfDriver,
          this.selectedGroup.logo
        );
      } else if (this.reportType == 'fuelChart') {
        this.genFuelChartData();
      } else if (this.reportType == 'tempChart') {
        this.genTempChartData();
      } else if (this.reportType == 'speedChart') {
        this.genSpeedChartData();
      } else if (
        this.reportType == 'idling' ||
        this.reportType == 'idlingSummary' ||
        this.reportType == 'idlingOpenPTO'
      ) {
        this.setReportLoading(true);
        await report.idlingReport(
          this.reportType == 'idlingSummary'
            ? ['ENGINE_IDLE_END', 'PTO_STOP']
            : ['ENGINE_IDLE_END'],
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.minuteOver * 60,
          this.geofences,
          dictOfDriver,
          this.reportType,
          this.selectedGroup.logo
        );
      } else if (
        this.reportType == 'summary' ||
        this.reportType == 'summaryByDay'
      ) {
        this.setReportLoading(true);

        await report.summaryReport(
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          selectedImeisTmp,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.selectedGroup.logo,
          this.reportType == 'summaryByDay' ? true : false
        );
      } else if (this.reportType == 'geofence_current_report') {
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/geofence-current-report/user`,
          {
            imeis: selectedImeisTmp,
            customergroup: this.selectedGroup.id,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const fileName =
              'Current_Geofence_' +
              moment.unix(moment().unix()).format('D_MMM_YY_h-mm-ss') +
              '.xlsx';

            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType == 'fuel_rate_report') {
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/report-fuel-rate`,
          {
            imeis: selectedImeisTmp,
            start: this.dateRange[0].unix(),
            stop: this.dateRange[1].unix(),
            customergroup: this.selectedGroup.id,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const strTimerange =
              moment.unix(this.dateRange[0].unix()).format('D_MMM_YY') +
              '-to-' +
              moment.unix(this.dateRange[1].unix()).format('D_MMM_YY');
            const fileName = 'Fuel_Report_' + strTimerange + '.xlsx';

            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType == 'fleet_summary_report') {
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/fleet-summary-report`,
          {
            imeis: selectedImeisTmp,
            start: this.dateRange[0].unix(),
            stop: this.dateRange[1].unix(),
            customergroup: this.selectedGroup.id,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const strTimerange =
              moment.unix(this.dateRange[0].unix()).format('D_MMM_YY') +
              '-to-' +
              moment.unix(this.dateRange[1].unix()).format('D_MMM_YY');
            const fileName = 'Fleet_Summary_Report_' + strTimerange + '.xlsx';
            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType === 'fuelUsedRateSummary') {
        this.setReportLoading(true);
        await report
          .fuelUsedRateSumReport(
            this.vehicles.map((vehicle) => {
              return {
                plate: vehicle.plate_number,
                imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
              };
            }),
            selectedImeisTmp,
            this.dateRange[0].unix(),
            this.dateRange[1].unix(),
            this.selectedGroup.logo
          )
          .then(() => {
            this.setReportLoading(false);
          });
      } else if (this.reportType === 'vehicleUsageSummary') {
        this.setReportLoading(true);
        await AxiosService.post(
          `${endpoints.coreApi}/vehicle-usage-summary-report`,
          {
            vehicles: this.vehicles.map((vehicle) => {
              return {
                plate: vehicle.plate_number,
                imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
              };
            }),
            imeis: selectedImeisTmp,
            start: this.dateRange[0].unix(),
            stop: this.dateRange[1].unix(),
            customergroup: this.selectedGroup.id,
          },
          {
            responseType: 'blob',
          }
        )
          .then(async (response) => {
            this.setReportLoading(false);
            const strTimerange =
              moment.unix(this.dateRange[0].unix()).format('D_MMM_YY') +
              '-to-' +
              moment.unix(this.dateRange[1].unix()).format('D_MMM_YY');
            const fileName =
              'Vehicle_Usage_Summary_Report_' + strTimerange + '.xlsx';

            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType === 'all_device_report') {
        this.setReportLoading(true);
        await AxiosService.get(`${endpoints.coreApi}/report-all-devices`, {
          responseType: 'blob',
        })
          .then(async (response) => {
            this.setReportLoading(false);

            const fileName = 'All_Devices_Report.xlsx';

            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.setReportLoading(false);
            console.log('An error occurred:', error);
          });
      } else if (this.reportType === 'enterExitGeofenceReport') {
        this.setReportLoading(true);
        await report.enterExitGeofenceReport(
          this.selectedVehicles,
          this.selectedGeofences,
          this.dateRange[0].unix(),
          this.dateRange[1].unix(),
          this.selectedGroup
        );
        this.setReportLoading(false);
      }
    },
    genFuelChartData() {
      this.setReportLoading(true);
      report
        .fuelChartData(
          this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              lastFuelLitre: vehicle.lastFuelLitre,
              oil_liters_max: vehicle.oil_liters_max,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          }),
          this.selectedVehicles,
          moment(this.selectedDate)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .unix(),
          moment(this.selectedDate)
            .hour(23)
            .minute(59)
            .second(59)
            .millisecond(999)
            .unix()
        )
        .then((data) => {
          this.resetChartData();
          if (data !== undefined) {
            this.fuelChartData = data.chartData;
            this.chartTotalDistance = data.totalDistance;
          } else {
            this.setReportLoading(false);
          }
        });
    },
    async genTempChartData() {
      this.setReportLoading(true);
      await AxiosService.post(`${endpoints.coreApi}/temp-chart`, {
        imei: this.selectedVehicles,
        start: this.dateRange[0].unix(),
        stop: this.dateRange[1].unix(),
      })
        .then((response) => {
          const { data } = response;
          if (data) {
            this.tempChartData = data.dataPoints;
            this.chartTotalDistance = data.totalDistance;
          }
          this.setReportLoading(false);
        })
        .catch((err) => {
          console.log(err);
          this.setReportLoading(false);
        });
    },
    genSpeedChartData() {
      this.setReportLoading(true);
      report
        .speedChartData(
          this.selectedVehicles,
          this.dateRange[0].unix(),
          this.dateRange[1].unix()
        )
        .then((data) => {
          this.resetChartData();
          if (data !== undefined) {
            this.speedChartData = data.chartData;
            this.overSpeedLimit = this.selectedVehicleData.overspeed_limit;
            this.chartTotalDistance = data.totalDistance;
          } else {
            this.setReportLoading(false);
          }
        });
    },
    clearData() {
      this.selectedVehicles = [];
      this.dateRange = [];
      this.reportType = 'daily';
    },

    displayDateCond() {
      if (this.reportType == 'geofence_current_report') return false;
      if (this.reportType == 'all_device_report') return false;
      return true;
    },
    translateChartType() {
      if (this.reportType == 'fuelChart') return 'เชื้อเพลิง';
      if (this.reportType == 'tempChart') return 'อุณหภูมิ';
      if (this.reportType == 'speedChart') return 'ความเร็ว';
      return '-';
    },
    onMultiGeofenceSelectChange(outputs) {
      if (outputs.length > 0) {
        this.selectedGeofences = [...outputs.map((output) => output.key)];
      } else {
        this.selectedGeofences = [];
      }
    },
    resetChartData() {
      this.fuelChartData = [];
      this.tempChartData = [];
      this.speedChartData = [];
      this.chartTotalDistance = '';
    },
    isChartType(reportType) {
      return ['fuelChart', 'tempChart', 'speedChart'].includes(reportType);
    },
  },
};
</script>
<style lang="scss" scoped>
.report-container {
  background-color: #fff !important;
}
.select-menu-item {
  font-size: 17px;
}
.sorry-message-container {
  .sorry-message {
    padding: 0 80px 0 0;
    h1 {
      font-size: 2rem;
    }
  }
  padding-top: 200px;
  img {
    display: block;
    margin: 0 auto 0 auto;
    width: 300px;
    max-width: 100%;
  }
}

.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.search-container {
  padding: 25px 15px 15px 15px;
  .ant-form {
    max-width: none;
  }
  .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
  }
}
</style>
