import { Icon } from 'ant-design-vue';

const env = process.env.NODE_ENV || 'local';
console.log('process.env.NODE_ENV', env);
let endpoints = {};

switch (env) {
  case 'production':
    endpoints = {
      graphAPI: 'https://graph.heliot.co.th/graphql',
      socket: 'https://realtime-data-v3.heliot.co.th/position',
      strapiAPI: 'https://control.heliot.co.th',
      strapiGraph: 'https://control.heliot.co.th/graphql',
      coreApi: 'https://core-api.heliot.co.th',
      coreApiTmp: 'https://core-api-tmp.heliot.co.th',
      richmorApi: 'https://richmor.heliot.co.th/api',
      jimiApi: 'https://jimi-video-api.heliot.co.th/api',
      firebaseVapidKey:
        'BInPxF4YG0Rz0YavqQ9b7fR5KAskF-8MKP0CooUSHpY47vfGxSK0FHbYh9-x8QTOf8vbR_-hO6aTDFFKlKdFt94',
      firebaseAPIServer: 'https://notification-api.heliot.co.th',
    };
    break;
  case 'staging':
    endpoints = {
      graphAPI: 'https://graph.uat-heliot.com/graphql',
      socket: 'https://realtime-data.uat-heliot.com/position',
      strapiAPI: 'https://old-control.uat-heliot.com',
      strapiGraph: 'https://old-control.uat-heliot.com/graphql',
      coreApi: 'https://core-api.uat-heliot.com',
      coreApiTmp: 'https://core-api-tmp.uat-heliot.com',
      richmorApi: 'https://richmor.uat-heliot.com/api',
      jimiApi: 'https://jimi-video-api.uat-heliot.com/api',
      firebaseVapidKey:
        'BJp2ND9YxpIGaYueiRN3qQLTM51ejsb8IKbci4pz74kLzyuLdvn1n25os1LzuHpKuDUoyA_5xCnGFN6fdQbIlZA',
      firebaseAPIServer: 'https://notification-api.uat-heliot.com',
    };
    break;

  default:
    //local
    endpoints = {
      graphAPI: 'https://graph.uat-heliot.com/graphql',
      socket: 'https://realtime-data.uat-heliot.com/position',
      strapiAPI: 'https://old-control.uat-heliot.com',
      strapiGraph: 'https://old-control.uat-heliot.com/graphql',
      coreApi: 'https://core-api.uat-heliot.com',
      coreApiTmp: 'https://core-api-tmp.uat-heliot.com',
      richmorApi: 'https://richmor.uat-heliot.com/api',
      jimiApi: 'https://jimi-video-api.uat-heliot.com/api',
      firebaseVapidKey:
        'BJp2ND9YxpIGaYueiRN3qQLTM51ejsb8IKbci4pz74kLzyuLdvn1n25os1LzuHpKuDUoyA_5xCnGFN6fdQbIlZA',
      firebaseAPIServer: 'https://notification-api.uat-heliot.com',
    };
    break;
}
// console.log(endpoints);
export default endpoints;

export const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1564507_e7ixsg362jt.js',
});

export const firebaseVapidKey = endpoints.firebaseVapidKey;
export const firebaseAPIServer = endpoints.firebaseAPIServer;
export const currentAppVersion = require('../../package.json').version;